<script>
export default {
    props:{
        data:Array,
        value: String
    },
    methods:{
        handleChange(e){
            this.$emit('input', e.target.value)
        },
        getLable(item){
            if(localStorage.getItem('language')!="zh-CN"){
                return item.labelEn
            }else{
                return item.label
            }
        }
    }
};
</script>
<template>
    <select class="form-select" @change="handleChange($event)" :value="value">
        <option value=""></option>
        <option v-for="item in data" :key="item.value" :selected="item.value==value" :value="item.value">{{getLable(item)}}</option>
    </select>
</template>