<script>
import simplebar from "simplebar-vue";
import gpselect from "./gpselect";
import DatePicker from "vue2-datepicker";
import {lookup2,getLookupItemText,trueText,formatDate} from '@/apis/common'
import {queryServiceOrder} from '@/apis/serviceorder'
import {queryStaffList} from '@/apis/user'
export default {
    props:{
        showTitle: Boolean,
        clientNo: String
    },
    components: { simplebar,gpselect,DatePicker},
    data() {
        return {
            showType: "list",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            tableData: [],
            items:[],
            showForm:false,
            serviceStatusShow:false,
            curOrder:null,
            staffList:[],
            filter:{
                createUser:"",
                serviceNo:"",
                serviceType:"",
                serviceStatus:"",
                worker:"",
                serviceDate:"",
                country:""
            },
            lookupRawData:{},
            lookupData:{
                orderType:[],
                serviceStatus:[],
                cnty:[],
            },
            pageRequest:{
                pageNum:1,
                pageSize:10
            }
        };
    },
    computed: {
    },
    watch:{
        "pageRequest.pageNum"() {
            this.loadData()
        },
        filter:{
            handler(){
                this.loadData();
            },
            deep:true
        }
    },
    created(){
        this.profileFormInit = {...this.profileForm}
        this.init();
        this.loadData();
        //初始化用户列表
        queryStaffList({roles:["AUDIT"]}).then((res)=>{
            this.staffList = res.data.map(item=>{ return {value:item.userName,label:item.staffName,labelEn:item.staffName}});
        })
    },
    mounted() {
    },
    methods: {
        formatDate,
        trueText,
        async init(){
            this.lookupRawData = await lookup2("ORDER-TYPE,SERVICE-STATUS,CNTY");
            this.lookupData.orderType = this.lookupRawData["ORDER-TYPE"];
            this.lookupData.serviceStatus = this.lookupRawData["SERVICE-STATUS"];
            this.lookupData.cnty = this.lookupRawData["CNTY"];
        },
        getParams(){
            let params = {...this.pageRequest,filter:this.filter}
            if(this.clientNo!=undefined && this.clientNo!=null){
                params.filterExt = {"clientNo":this.clientNo}
            }
            return params
        },
        loadData(){
            const params = this.getParams();
            queryServiceOrder(params).then((res)=>{
                this.tableData = res.data;
                this.totalRows = res.page.total;
            });
        },
        handleDetail(serviceData){
            this.$router.push({
                name: 'service-order-detail',
                params: {
                    serviceId: serviceData.serviceId
                }
            })
        },
        formatLookupText(typeCode,value){
            return getLookupItemText(this.lookupRawData[typeCode],value);
        },
        formatServiceStatus(status){
            switch(status){
                case "PS": return this.$t("serviceOrder.stepPS")
                case "SC": return this.$t("serviceOrder.stepSC")
                case "P": return this.$t("serviceOrder.stepP")
                case "IP": return this.$t("serviceOrder.stepIP")
                case "PP": return this.$t("serviceOrder.stepPP")
                case "PR": return this.$t("serviceOrder.stepPR")
                case "RF": return this.$t("serviceOrder.stepRF")
                case "F": return this.$t("serviceOrder.stepF")
            }
        },
        handleFollowup(serviceData){
            this.$router.push({
                name: "follow-up",
                query: {
                    id: serviceData.serviceId,
                    type:'service',
                }
            })
        },
        statusStyle(serviceStatus){
            return {
                'badge-soft-info':serviceStatus=='PS',
                'badge-soft-primary':serviceStatus=='SC',
                'badge-soft-success':serviceStatus=='P',
                'badge-soft-warning':serviceStatus=='IP'||serviceStatus=='PP'||serviceStatus=='PR'||serviceStatus=='PR',
                'badge-soft-danger':serviceStatus=='F'            
            }
        },
        workerName(worker){
            let curWorker = this.staffList.filter(item=>{ return item.value==worker})
            if(curWorker.length==1){
                return curWorker[0].label
            }else{
                return ""
            }
        }
    }
};
</script>

<template>
            <div class="row">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3" v-if="showTitle">
                            <h5 class="card-title">{{$t("serviceOrder.pageTitle")}}</h5>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                            <div>
                                <ul class="nav nav-pills">
                                    <li class="nav-item">
                                        <a class="nav-link" v-bind:class="showType=='list' ? 'active' : ''" data-bs-toggle="tooltip" title="List" @click="showType='list'"><i class="uil uil-list-ul"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" v-bind:class="showType=='grid' ? 'active' : ''" data-bs-toggle="tooltip" data-bs-placement="top" title="Grid"  @click="showType='grid'"><i class="uil uil-apps"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div>                                
                                <button type="button" class="btn btn-light text-truncate ms-2"  @click="loadData"><i class="ion ion-md-refresh me-1 align-middle"></i> {{$t("actions.refresh")}}</button>
                            </div>
                        </div>
                    </div><!-- end col -->
                </div><!-- end row -->
                <div v-if="showType=='grid'">
                    <div class="row">
                        <div class="col-xl-4 col-sm-6" v-for="row in tableData" :key="row.serviceNo">
                            <div class="card">
                                <div class="card-body p-4">
                                    <div class="d-flex align-items-start">
                                        <div class="flex-grow-1 overflow-hidden">
                                            <h5 class="font-size-15 mb-1 text-truncate">
                                                <a href="javascript:" class="text-dark"  @click="handleDetail(row)">{{row.client.clientName}}</a>
                                            </h5>
                                            <span class="badge me-1" :class="statusStyle(row.serviceStatus)">{{formatServiceStatus(row.serviceStatus)}}</span>
                                        </div>
                                    </div>
                                    <p class="mt-4 text-muted">{{$t("serviceOrder.serviceNo")}}：{{ row.serviceNo }}<br></p>
                                    <div class="pt-2">
                                        <button type="button" class="btn btn-primary btn-sm text-truncate ms-2"  @click="handleDetail(row)"><i class="typcn typcn-document-text me-1 align-middle"></i> {{$t("actions.view")}}</button>
                                        <button type="button" class="btn btn-primary btn-sm text-truncate ms-2"  @click="handleFollowup(row)"><i class="typcn typcn-document-text me-1 align-middle"></i> {{$t("actions.followup")}}</button>
                                    </div>
                                </div>
                                <!-- end card body -->
                            </div><!-- end card -->
                        </div><!-- end col -->
                    </div><!-- end row -->
                </div>
                <div v-if="showType=='list'">
                    <div class="table-responsive">
                        <simplebar class="h-100">
                        <table class="table align-middle table-nowrap table-check">
                            <thead>
                                <tr>
                                    <th scope="col">{{$t("serviceOrder.serviceNo")}}</th>
                                    <th scope="col" v-if="showTitle">{{$t("client.name")}}</th>
                                    <th scope="col">{{$t("order.country")}}</th>
                                    <th scope="col">{{$t("serviceOrder.type")}}</th>
                                    <th scope="col">{{$t("serviceOrder.status")}}</th>
                                    <th scope="col">{{$t("serviceOrder.personday")}}</th>
                                    <th scope="col">{{$t("serviceOrder.serviceDate")}}</th>
                                    <th scope="col">{{$t("serviceOrder.auditor")}}</th>
                                    <th scope="col">{{$t("common.createDate")}}</th>
                                    <th style="width: 80px; min-width: 80px;">{{$t("actions.actions")}}</th>
                                </tr><!-- end tr -->
                            </thead><!-- end thead -->
                            <tbody>
                                <tr class="data-filter" v-if="showTitle">
                                    <td>
                                        <input type="text" class="form-control" v-model="filter.serviceNo">
                                    </td>
                                    <td><input type="text" class="form-control" v-model="filter.createUser"></td>
                                    <td>
                                        <gpselect
                                        :data="lookupData.cnty"
                                        v-model="filter.country"
                                        />
                                    </td>
                                    <td>
                                        <gpselect
                                        :data="lookupData.orderType"
                                        v-model="filter.serviceType"
                                        />
                                    </td>
                                    <td>
                                        <gpselect
                                        :data="lookupData.serviceStatus"
                                        v-model="filter.serviceStatus"
                                        />
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        <date-picker
                                            v-model="filter.serviceDate"
                                            format="YYYY-MM-DD"
                                            value-type="YYYY-MM-DD HH:MM:SS"
                                            :first-day-of-week="1"
                                            lang="en"
                                            ></date-picker>
                                    </td>
                                    <td>
                                        <gpselect
                                        :data="staffList"
                                        v-model="filter.worker"
                                        />
                                    </td>
                                    <td>

                                    </td>
                                    <td>                                       
                                    </td>
                                </tr><!-- end tr -->
                                <tr v-for="row in tableData" :key="row.orderNo">
                                    <td><a class="text-body fw-medium" href="javascript:" @click="handleDetail(row)">{{row.serviceNo}}</a></td>
                                    <td v-if="showTitle"> <a class="text-body fw-medium" href="javascript:" @click="handleDetail(row)">{{row.client.clientName}}</a></td>
                                    <td>{{formatLookupText('CNTY',row.country)}}</td>
                                    <td>{{formatLookupText('ORDER-TYPE',row.serviceType)}}</td>
                                    <td><span class="badge me-1" :class="statusStyle(row.serviceStatus)">{{formatServiceStatus(row.serviceStatus)}}</span></td>
                                    <td>{{row.workDay}}</td>
                                    <td>{{formatDate(row.serviceDate,"yyyy-MM-dd")}}</td>
                                    <td>{{workerName(row.worker)}}</td>
                                         <td>{{row.createDate}}</td>
                                    <td>
                                        <b-dropdown variant="link" menu-class="dropdown-menu-end">
                                            <template v-slot:button-content>
                                                <button class="btn btn-light btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="uil uil-ellipsis-h"></i>
                                                </button>
                                            </template>
                                            <b-dropdown-item @click="handleDetail(row)" ><i class="typcn typcn-document-text me-1"></i> {{$t("actions.view")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleFollowup(row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.followup")}}</b-dropdown-item>
                                        </b-dropdown>                                               
                                    </td>
                                </tr><!-- end tr -->
                            </tbody><!-- end tbody -->
                        </table><!-- end table -->
                        </simplebar>
                    </div>
                    
                </div>
                <div class="row g-0 text-center text-sm-start" v-if="totalRows>0">
                    <div class="col-sm-6">
                        <div>
                            <p class="mb-sm-0">Showing {{(pageRequest.pageNum-1)*pageRequest.pageSize+1}} to {{pageRequest.pageNum*pageRequest.pageSize}} of {{totalRows}} entries</p>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-sm-6">
                        <ul class="pagination pagination-rounded justify-content-center justify-content-sm-end mb-sm-0">
                            <b-pagination v-model="pageRequest.pageNum" :total-rows="totalRows" :per-page="pageRequest.pageSize"></b-pagination>
                        </ul><!-- end ul -->
                    </div><!-- end col -->
                </div><!-- end row -->                    
            </div><!-- end card -->
</template>
<style lang="scss" scoped>
.finish{
    vertical-align: super;
    color: red;
}
.border-light {
  border-color: #f8f7fc !important;
}
.bg-light {
    background-color: rgba(248, 247, 252,1)!important;
}
.search-box{
  .form-control{
      border-radius: 30px;
      padding-left: 40px;
  }
  .search-icon{
      font-size: 16px;    
      position: absolute;
      left: 13px;
      top: 2px;
      color: #959396;
      line-height: 38px;
  }
}
</style>