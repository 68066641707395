import request from '@/request'
export const serviceOrderDetail = (serviceId) => {
    return request({
        url:`/api-gateway/service/serviceOrderDetail/${serviceId}`,
        method:'get'
    });
}
export const queryServiceOrder = (data) => {
    return request({
        url:`/api-gateway/service/queryServiceOrder/${data.pageSize}/${data.pageNum}`,
        method:'post',
        data,
    });
}
export const saveServiceOrder = (data) => {
    return request({
        url:'/api-gateway/service/saveServiceOrder',
        method:'post',
        data,
    });
}
export const setServiceOrderStatus = (data) => {
    return request({
        url:'/api-gateway/service/setServiceOrderStatus',
        method:'post',
        data,
    });
}
export const setServiceOrderCS = (data) => {
    return request({
        url:'/api-gateway/service/setServiceOrderCS',
        method:'post',
        data,
    });
}
export const reportReviewApplication = (data) => {
    return request({
        url:'/api-gateway/service/reportReviewApplication',
        method:'post',
        data,
    });
}
export const reportReviewConfirm = (data) => {
    return request({
        url:'/api-gateway/service/reportReviewConfirm',
        method:'post',
        data,
    });
}
export const serviceOrderSchedule = (data) => {
    return request({
        url:'/api-gateway/service/serviceOrderSchedule',
        method:'post',
        data,
    });
}
export const generateConfirmLetter = (data) => {
    return request({
        url:'/api-gateway/service/generateConfirmLetter',
        method:'post',
        data,
    });
}
export const queryServiceClientFile = (serviceId,fileType) => {
    return request({
        url:`/api-gateway/service/queryServiceClientFile/${serviceId}/${fileType}`,
        method:'post'
    });
}
export const queryServiceWorkFile = (serviceId) => {
    return request({
        url:`/api-gateway/service/queryServiceWorkFile/${serviceId}`,
        method:'post'
    });
}
export const queryGuideline = (serviceType) => {
    return request({
        url:`/api-gateway/service/queryGuideline/${serviceType}`,
        method:'post'
    });
}
export const deleteWorkFile = (data) => {
    return request({
        url:`/api-gateway/service/deleteWorkFile`,
        method:'post',
        data,
    });
};
export const queryServiceOrderByDate = (data) => {
    return request({
        url:`/api-gateway/service/queryServiceOrderByDate`,
        method:'post',
        data,
    });
}


