<script>
import Layout from "../../layouts/main";
import Serviceorder from "../../components/serviceorder";
import appConfig from "@/app.config";
export default {
  page: {
    title: "Service Order",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,Serviceorder },
  data() {
    return {
      title: "Service Order",
    };
  },
  computed: {
  },
  created(){
    this.init();
  },
  mounted() {
  },
  methods: {
    async init(){

    },
  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <serviceorder show-title></serviceorder>
                </div>
            </div>
        </div><!-- end col -->
    </div><!-- end row -->
  </Layout>
</template>
<style lang="scss" scoped>
.border-light {
  border-color: #f8f7fc !important;
}
.bg-light {
    background-color: rgba(248, 247, 252,1)!important;
}
.search-box{
  .form-control{
      border-radius: 30px;
      padding-left: 40px;
  }
  .search-icon{
      font-size: 16px;    
      position: absolute;
      left: 13px;
      top: 2px;
      color: #959396;
      line-height: 38px;
  }
}
</style>